import PortalProgram from '@classes/Program'
import { LMSLearningPlansAttachmentsActivities } from '@interfaces/LearningPath'
import { ACTIVITY_LINKS } from '@utils/constants/learningPath'
import { getAttachmentInfo } from '@utils/lp-attachments'

class PortalLearningPathAttachment {
  attachments?: LMSLearningPlansAttachmentsActivities
  StudentProgram?: PortalProgram

  constructor(attachments?: LMSLearningPlansAttachmentsActivities, StudentProgram?: PortalProgram) {
    this.attachments = attachments
    this.StudentProgram = StudentProgram
  }

  get Name() {
    return getAttachmentInfo(this.attachments?.type ?? '')
  }

  get Url() {
    return this.attachments?.url ?? ''
  }

  get ActionPlanUrl() {
    return this.attachments?.actionplanurl ?? ''
  }

  get ResetUrl() {
    return this.attachments?.reseturl ?? ''
  }

  get Type() {
    return this.attachments?.type ?? ''
  }

  isTypedActivity = profileType =>
    this.Type === ACTIVITY_LINKS[profileType].type || this.Type === ACTIVITY_LINKS[profileType].otherType

  isOtherActivity = profileType => ACTIVITY_LINKS[profileType].otherLinks.includes(this.Type)
}

export default PortalLearningPathAttachment
