import PortalLearningPathAttachment from '@classes/LearningPathAttachments'

// attachments are activities since it is extended
class PortalLearningPathActivity extends PortalLearningPathAttachment {
  get Language() {
    return this.attachments?.language
  }

  get LanguageCode() {
    return this.attachments?.language?.code
  }

  get LanguageName() {
    return this.attachments?.language?.name
  }
}

export default PortalLearningPathActivity
