const materials = {
  sg: 'Student guide',
  ig: 'Instructor guide',
  ttt: 'Time to talk',
  p5_instructor: 'Instructor guide',
  p20: 'Online practice',
  p5_student: '(New) P5 Adapted Online Practice',
  sg_pdf: 'Student Guide (PDF)',
  ig_pdf: 'Instructor Guide (PDF)',
  ttt_pdf: 'Time to Talk PDF',
  p5_instructor_pdf: 'Instructor Guide PDF',
  sg_audio_zip: 'MP3 Audio',
  article_pdf: 'HBR article PDF',
  homework_pdf: 'Homework PDF',
  presenter_zip: 'Adobe Presenter SG slides',
  presenter_unpacked: 'Adobe Presenter',
  content_slides: 'Live-online Classroom Slides',
  course_sg_pdf: 'Full Student Guide PDF',
  course_ig_pdf: 'Full Instructor Guide PDF',
  course_audio_zip: 'Zipped Audio files (MP3)',
  course_audio_m4b: 'Audiobook (M4B format)',
  course_audio_script: 'Audio Script',
  course_audio_script_pdf: 'Audio Script PDF',
  ib_pdf: 'Full Illustration Book PDF',
  'Culture unit - p20': 'Culture unit',
}

export const getAttachmentInfo = (type: string): string => {
  return materials[type] || ''
}
