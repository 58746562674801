import moment from 'moment'

export const addBusinessDays = (date: string, numberOfDays: number) => {
  let actualDays = 0
  let dayCtr = 0

  while (dayCtr != numberOfDays) {
    actualDays++
    let dayIndex = moment(date)
      .add(actualDays, 'day')
      .day()

    if (dayIndex !== 0 && dayIndex !== 6) {
      dayCtr++
    }
  }

  return moment(date).add(actualDays, 'day')
}
