import { PROFILE_TYPE } from '@utils/constants/index'

const LMS_PRODUCT_ACTIVITIES = {
  FLEX_STUDENT: 'p5_student',
  FLEX_CULTURE_ACTIVITY_TYPE: 'p20',
  CURRICULUM_TEST: 'p20',
}

export const LP_STATUS = {
  COURSE_META: 'courseMeta',
  IN_PROGRESS: 'inprogress',
  COMPLETE: 'complete',
  COMPLETED: 'completed',
  REVIEW: 'review',
  NOT_YET_STARTED: 'notyetstarted',
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
  //
  CUSTOM_BOOKED: 'booked',
}

export const FLEX_ACTIVITY_LINK = LMS_PRODUCT_ACTIVITIES.FLEX_STUDENT
export const CULTURE_ACTIVITY_TYPE = LMS_PRODUCT_ACTIVITIES.FLEX_CULTURE_ACTIVITY_TYPE
export const CURRICULUM_TEST_ACTIVITY_TYPE = LMS_PRODUCT_ACTIVITIES.CURRICULUM_TEST

export const ACTIVITY_LINKS = {
  [PROFILE_TYPE.STUDENT]: {
    type: 'sg',
    otherLinks: ['op', 'p20'],
    omitAttachments: ['ig_pdf', 'p5_instructor_pdf', 'course_ig_pdf'],
  },
  [PROFILE_TYPE.INSTRUCTOR]: {
    type: 'ig',
    otherType: 'sg',
    otherLinks: ['p20'],
  },
}

export const LP_UNIT_TYPE = {
  LESSON: 'default',
  CULTURE: 'culture',
  REVIEW: 'review',
  CHECKPOINT: 'checkpoint',
  COACHING: 'coaching',
  INSTRUCTIONAL: 'instructional',
  MATERIAL: 'material',
  DEFAULT: 'default',
}

export const LP_UNIT_LESSON_STATUS = {
  NOT_YET_STARTED: 'notyetstarted',
  COMPLETE: 'complete',
  INPROGRESS: 'inprogress',
}

export const DETAILS_TYPE_DEFAULT = 'basic'
export const LP_COURSE_META_CUSTOM_TITLE = 'Material'

export const PREFIX_LIVE_COACHING_SESSION = 'Live Coaching Session'
export const PREFIX_REVIEW_LESSON = 'Review Lesson'
export const MATERIAL_OPTIONS_PREFIXES = [PREFIX_LIVE_COACHING_SESSION, PREFIX_REVIEW_LESSON]

export const LP_JAPAN_BOOKING_URL = 'https://berlitz-portal.jp/'
export const LP_LANGUAGE_FRENCH = 'French'
export const LP_LANGUAGES = [LP_LANGUAGE_FRENCH]
